import { h } from "preact";
// import { Link } from 'preact-router/match';
import { useState } from "preact/hooks";
import style from "./style.css";

const Header = () => {
  const [overlayView, setOverlayView] = useState(false);

  const openMenu = () => {
    setOverlayView(!overlayView);
  };
  return (
    <div class={style.homeWrapper}>
      <section class={style.homeTopContainer}>
        <div class={style.homeTopInnerContainer}>
          <header class={style.headerOuterWrap}>
            <div class={style.headerInnerWrap}>
              <div class={style.mobileMenu}>
                <a href="javascript:void(0);" onClick={openMenu}>
                  <img src="../../assets/images/menu.svg" alt="menu" />
                </a>
              </div>
              <div class={style.headerLeftBlock}>
                <img
                  src="../../assets/images/amblem.png"
                  class={style.amblemImg}
                  alt="Indian Amblem"
                />
                <span class={style.logodivider} />
                <img
                  src="../../assets/images/logo-as.svg"
                  class={style.logoSetu}
                  alt="Aarogya Setu"
                />
              </div>
              <div class={style.headerRightBlock}>
                <nav class={style.navBar}>
                  <a href="/#howItWork">How does it work?</a>
                  <a href="/#whySetu">Why Aarogya Setu?</a>
                  {/* <a href="#faqSetu">FAQs</a> */}
                </nav>
              </div>
            </div>
          </header>
          {overlayView && (
            <div>
              <div class={style.popOverlay} />
              <div class={style.popBody}>
                <div class={style.popContent}>
                  <div class={style.popHead}>
                    <a href="javascript:void(0);">
                      <img
                        src="../../assets/images/close.svg"
                        alt="close"
                        onClick={openMenu}
                      />
                    </a>
                  </div>
                  <div class={style.popBodyWrap}>
                    <a href="/" class={style.menuLinkText} onClick={openMenu}>
                      Home
                    </a>
                    <a
                      href="/#howItWork"
                      class={style.menuLinkText}
                      onClick={openMenu}
                    >
                      How does it work?
                    </a>
                    <a
                      href="/#whySetu"
                      class={style.menuLinkText}
                      onClick={openMenu}
                    >
                      Why Aarogya Setu?
                    </a>
                    <a
                      href="/#faqSetu"
                      // class={style.menuLinkText}
                      class={style.dn}
                      onClick={openMenu}
                    >
                      FAQs
                    </a>
                    <a
                      href="/verify"
                      class={style.menuLinkText}
                      onClick={openMenu}
                    >
                      Verify App
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Header;
